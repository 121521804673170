import React, { Fragment } from 'react';
import styles from './hero.module.scss';
import Button from '@components/Button';
import CardWithImage from '@components/CardWithImage';
import { Grid, Row, Divider, Col } from '@element-softworks/daintree';
import Img from 'gatsby-image';
import SVG from 'react-inlinesvg';

const Hero = ({ title, text, bottomText, heroCards, processRef }) => {
    return (
        <Fragment>
            <div className={styles.homeHeroWrapper}>
                <div className={styles.backgroundContainer}>
                    <SVG src="/images/home-hero-background.svg" alt="background" />
                    <div className={styles.heading}>
                        <h1>{title}</h1>
                        <p>{text}</p>
                    </div>
                </div>
                <div className={styles.midgroundContainer}>
                    <img src="/images/home-hero-midground-3.svg" alt="midground" />
                </div>
                <div className={styles.foregroundContainer}>
                    <img src="/images/home-hero-foreground-2.svg" alt="foreground" />
                    <div className={styles.content}>
                        <h2>Our Mission</h2>
                        <p>{bottomText}</p>
                        <div className={styles.downloadButton}>
                            <Button text="Why LiveYou? - About Us" link="/about" />
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.heroCardsWrapper}>
                <Grid>
                    <Row center={'xs'}>
                        {heroCards?.map(({ title, text, link, linkText, image, scroll }) => (
                            <Col lg={4} sm={6} xs={12} key={title}>
                                {!!scroll ? (
                                    <CardWithImage
                                        image={image?.childImageSharp?.fluid}
                                        title={title}
                                        text={text}
                                        buttonText={linkText}
                                        scroll={scroll}
                                        processRef={processRef}
                                    />
                                ) : (
                                    <CardWithImage
                                        image={image?.childImageSharp?.fluid}
                                        title={title}
                                        text={text}
                                        buttonText={linkText}
                                        buttonLink={link}
                                    />
                                )}
                            </Col>
                        ))}
                    </Row>
                </Grid>
            </div>
        </Fragment>
    );
};

export default Hero;
