import React, { forwardRef } from 'react';
import styles from './contact.module.scss';
import ContactUsForm from '@components/ContactForm/ContactForm';

const ContactSection = ({ title }, ref) => {
    return (
        <div className={styles.contactWrapper} ref={ref}>
            <div className={styles.contactHeader}>
                {!!title && <div dangerouslySetInnerHTML={{ __html: title }} />}
            </div>
            <ContactUsForm />
        </div>
    );
};

export default forwardRef(ContactSection);
