import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './partners.module.scss';
import Img from 'gatsby-image';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Grid, Row, Col } from '@element-softworks/daintree';
import Button from '@components/Button';

const Partners = ({ title, partners }) => {
    const [swiper, setSwiper] = useState(null);
    const [slideIndex, setSlideIndex] = useState(0);
    const activePartner = partners?.[slideIndex];

    const handleSlideIndex = swiper => {
        const nextIndex = swiper.realIndex + 1;

        setSlideIndex(
            window?.innerWidth <= 680
                ? swiper.realIndex
                : nextIndex === partners?.length
                ? 0
                : nextIndex
        );
    };

    return (
        <div className={styles.partnersWrapper}>
            <div className={styles.partnersHeader}>
                <h2>{title}</h2>
            </div>
            <div className={styles.partnersSwiperContainer}>
                <Swiper
                    slidesPerView={3}
                    onInit={swiper => {
                        setSwiper(swiper);
                    }}
                    loop
                    onSlideChange={handleSlideIndex}
                    breakpoints={{
                        320: {
                            slidesPerView: 1,
                        },
                        680: {
                            slidesPerView: 3,
                        },
                    }}
                >
                    {partners?.map(({ description, link, logo, title }, index) => (
                        <SwiperSlide key={`${title}_${index}`}>
                            <div
                                className={`${styles.partner} ${
                                    slideIndex === index ? styles.partnerActive : ''
                                }`}
                            >
                                <div className={styles.partnerImage}>
                                    <Img
                                        fluid={logo?.childImageSharp?.fluid}
                                        alt={title}
                                        imgStyle={{ objectFit: 'contain' }}
                                    />
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
                {!!activePartner && (
                    <div className={styles.partnerContent}>
                        <div className={styles.partnerContentTitle}>
                            <FontAwesomeIcon
                                icon={['far', 'long-arrow-left']}
                                onClick={() => swiper?.slidePrev?.()}
                            />
                            <h3>{activePartner?.title}</h3>
                            <FontAwesomeIcon
                                icon={['far', 'long-arrow-right']}
                                onClick={() => swiper?.slideNext?.()}
                            />
                        </div>
                        <img src="/images/squiggle.svg" alt="a squiggle line" />
                        <p>{activePartner?.description}</p>
                        <Button text={`Visit ${activePartner?.title}`} link={activePartner?.link} />
                    </div>
                )}
            </div>
        </div>
    );
};

export default Partners;
