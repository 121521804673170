import React, { Fragment, useEffect, useRef, useState } from 'react';
import styles from './process.module.scss';
import Button from '@components/Button';
import { Grid, Row, Divider, Col } from '@element-softworks/daintree';
import TapTextCard from '@components/TapTextCard';
import Img from 'gatsby-image';
import Accordion from '@components/Accordion';

const Process = ({ description, image, title, items }, ref) => {
    const $wrapper = useRef(null);
    const $image = useRef(null);
    const [openItemIndex, setOpenItemIndex] = useState(null);
    const [toggleBlur, setToggleBlur] = useState(false);
    const activeItem = items?.[openItemIndex];
    const [offset, setOffset] = useState(null);

    useEffect(() => {
        window?.addEventListener('scroll', handleScroll);
        handleScroll();
        return () => window?.removeEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
        setToggleBlur(true);
        let timer1 = setTimeout(() => setToggleBlur(false), 800);

        return () => {
            clearTimeout(timer1);
        };
    }, [openItemIndex]);

    const handleScroll = () => {
        // setOffset($image?.current?.getBoundingClientRect?.().top / 4);
        // window user hasn't scrolled to the graphic yet (plus 40 px padding)
        if (window?.pageYOffset <= $wrapper?.current?.offsetTop + 40) {
            return;
        }

        if (
            window?.pageYOffset >=
            $wrapper?.current?.offsetTop +
                $wrapper?.current.offsetHeight -
                $image?.current?.offsetHeight
        ) {
            setOffset($wrapper?.current.offsetHeight - $image?.current?.offsetHeight + 40);
            return;
        }

        setOffset(window?.pageYOffset + 40 - $wrapper?.current?.offsetTop);
    };

    return (
        <div className={styles.processWrapper} ref={ref}>
            <div className={styles.processContainer}>
                <div className={styles.processHeader}>
                    <h2>{title}</h2>
                    <p>{description}</p>
                </div>
                <div className={styles.processContent} ref={$wrapper}>
                    <Row around="xs" end={'lg'}>
                        <Col xl={5} lg={5} md={12} sm={12} xs={12} last={'lg'}>
                            <div className={styles.processImageWrapper}>
                                <div>
                                    {!activeItem && (
                                        <div className={styles.staticImageContainer}>
                                            <Img
                                                fluid={image?.childImageSharp?.fluid}
                                                alt={title}
                                                className={toggleBlur ? styles.blur : ''}
                                            />
                                        </div>
                                    )}
                                    <div
                                        className={styles.imageContainer}
                                        style={{
                                            transform: !offset ? null : `translateY(${offset}px)`,
                                        }}
                                    >
                                        <div className={styles.imageAnimate}>
                                            <div className={styles.image} ref={$image}>
                                                {!!activeItem && (
                                                    <img
                                                        src={activeItem?.image?.publicURL}
                                                        alt={activeItem?.title}
                                                        className={toggleBlur ? styles.blur : ''}
                                                    />
                                                )}
                                                {/*<Img*/}
                                                {/*    fluid={image?.childImageSharp?.fluid}*/}
                                                {/*    alt={title}*/}
                                                {/*/>*/}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col xl={7} lg={7} md={12} sm={12} xs={12}>
                            <div
                                className={styles.processCardList}
                                // onClick={() => setToggleBlur(!toggleBlur)}
                            >
                                {items?.map(({ text, title }, index) => (
                                    <Fragment key={`${title}_${text}_${index}`}>
                                        {!!index && <Divider />}
                                        <Accordion
                                            open={openItemIndex === index}
                                            onToggle={open => {
                                                setOpenItemIndex(open ? index : null);
                                                // openItemIndex != null &&
                                                // open &&
                                                ref?.current?.scrollIntoView({
                                                    behavior: 'smooth',
                                                });
                                            }}
                                            title={title}
                                            html={text}
                                            scrollOnClick={true}
                                            wrapperRef={ref}
                                        />
                                    </Fragment>
                                ))}
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
};

export default React.forwardRef(Process);
