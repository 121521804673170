import React, { useRef } from 'react';
import styles from './styles.module.scss';
import Layout from '@components/Layout';
import Hero from '@components/Home/Hero';
import Process from '@components/Home/Process';
import Partners from '@components/Home/Partners';
import ContactSection from '@components/Home/ContactSection';
import { graphql } from 'gatsby';
import SEO from '@components/SEO';
import Button from '@components/Button';
import { Grid, Row, Divider, Col } from '@element-softworks/daintree';
import GoingToUniBanner from '@components/GoingToUniBanner';

const Home = ({
    data: {
        page: {
            frontmatter: {
                ourProcessItems,
                ourProcessTitle,
                ourProcessDescription,
                ourProcessImage,
                heroTitle,
                heroText,
                heroDescription,
                heroImage,
                ourMissionTitle,
                ourMissionDescription,
                partnersTitle,
                partnersItems,
                contactTitle,
                heroCards,
            },
        },
    },
}) => {
    const $process = useRef(null);
    const $contact = useRef(null);

    return (
        <Layout>
            <SEO description="LiveYou is a user-led organisation which specialises in helping young adults with physical disabilities live independently and achieve their goals by creating bespoke personal assistant (PA) support packages for them" />
            <div className={styles.homePageWrapper}>
                <Hero
                    title={heroTitle}
                    text={heroText}
                    bottomText={heroDescription}
                    image={heroImage}
                    heroCards={heroCards}
                    processRef={$process}
                    contactRef={$contact}
                />
                <Grid>
                    <div id={'processes'}>
                        <Process
                            ref={$process}
                            title={ourProcessTitle}
                            description={ourProcessDescription}
                            image={ourProcessImage}
                            items={ourProcessItems}
                        />
                    </div>
                </Grid>

                <Divider />
                <Divider />

                {/*<div className={styles.missionSection}>*/}
                {/*    <Grid>*/}
                {/*        <div className={styles.missionContent}>*/}
                {/*            <h2>{ourMissionTitle}</h2>*/}
                {/*            <p>{ourMissionDescription}</p>*/}
                {/*            <Button text="Why LiveYou? - About Us" link="/about" />*/}
                {/*        </div>*/}
                {/*    </Grid>*/}
                {/*</div>*/}

                <GoingToUniBanner displayButton />

                <Divider />
                <Divider />

                <Grid>
                    <Partners title={partnersTitle} partners={partnersItems} />
                    <Divider margin={4} />
                    <Divider margin={4} />
                    <Divider margin={4} />
                    <ContactSection title={contactTitle} ref={$contact} />
                </Grid>
            </div>

            <Divider />
            <Divider />
        </Layout>
    );
};

export default Home;

export const query = graphql`
    query homePage($path: String!) {
        page: markdownRemark(frontmatter: { path: { eq: $path } }) {
            frontmatter {
                heroTitle: home_hero_title
                heroImage: home_hero_image {
                    childImageSharp {
                        fluid {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                heroText: home_hero_text
                heroDescription: home_hero_description
                heroCards: home_hero_cards {
                    title: home_hero_cards_card_title
                    text: home_hero_cards_card_text
                    link: home_hero_cards_card_link
                    scroll: home_hero_card_card_scroll_link
                    linkText: home_hero_cards_card_link_text
                    image: home_hero_cards_card_image {
                        childImageSharp {
                            fluid {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                ourProcessItems: home_our_process_items {
                    text: home_our_process_items_item_text
                    image: home_our_process_items_item_image {
                        publicURL
                    }
                    title: home_our_process_items_item_title
                }
                ourProcessImage: home_our_process_image {
                    childImageSharp {
                        fluid {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                ourProcessDescription: home_our_process_description
                ourProcessTitle: home_our_process_title
                ourMissionTitle: our_mission_title
                ourMissionDescription: our_mission_description
                partnersTitle: home_partners_title
                partnersItems: home_partners_items {
                    logo: home_partners_items_logo {
                        childImageSharp {
                            fluid {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                    link: home_partners_items_link
                    title: home_partners_items_title
                    description: home_partners_items_description
                }
                contactTitle: home_contact_title
            }
        }
    }
`;
